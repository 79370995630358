import { Component, LOCALE_ID } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { RouterOutlet } from '@angular/router';
import { slideInAnimation } from './animations';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [{ provide: LOCALE_ID, useValue: 'es' }],
  animations: [slideInAnimation],
})
export class AppComponent {
  title = 'sisban';

  constructor(
    private dateAdapter: DateAdapter<any>,
    private http: HttpClient
  ) {
    this.setEspaniol();
    // this.getUserStatus();
  }

  getAnimationData(outlet: RouterOutlet) {
    return (
      outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData['animation']
    );
  }

  setEspaniol() {
    this.dateAdapter.setLocale('es');
  }
  getUserStatus() {
    console.log('entrostatus');

    this.http.post('/usuario/status', '').subscribe(
      (result) => {
        console.log(result);
        console.log(Object.keys(result));
        console.log(Object.keys(result)[0]);
        console.log(result[Object.keys(result)[0]]);
        // $('#usuariolabel').text('Usuario:	 ' + result[Object.keys(result)[1]]);
      },
      (error) => {
        console.log('problemas');
      }
    );
  }
}
