import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import Swal from 'sweetalert2';

export interface SessionData {
  username: string;
  password: string;
}

@Component({
  selector: 'app-logincomponent',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  title = 'login';
  hide = true;
  invalid = false;
  username = '';
  password = '';

  constructor(private http: HttpClient) { }

  ngOnInit() { }

  // usuarioControl = new FormControl('', [Validators.required]);
  // passControl = new FormControl('', [Validators.required]);


  acceder() {
    console.log('Acceder');
    // this.usuarioControl.markAsTouched();
    // this.passControl.markAsTouched();
    // !this.usuarioControl.invalid && !this.passControl.invalid
    // let username = $('#username').val() + '';
    // let password = $('#password').val() + '';
    console.log(this.username);
    console.log(this.password);
    if (this.username === '' || this.password === '') {
      this.invalid = true;
      console.log('Invalid');
    } else {
      this.invalid = false;
      // const username = this.usuarioControl.value;
      // const password = this.passControl.value;
      const formsave: SessionData = {
        username: this.username,
        password: this.password
      } as SessionData;
      this.http.post < SessionData > ('/user/login', formsave).subscribe(
            respuesta => {
              console.log('Entro');
              console.log(respuesta);
              const code = respuesta + '';
              if (code === '1') {
                window.location.href = '';
              } else {
                console.log('No se encontro el usuario');
                Swal.fire('No se encontro el usuario', 'No hay coincidencia', 'error');
              }
            }
          );
    }
  }
  redirectIndex() {
    window.location.href = '/';
  }
  getErrorMessageUsuario() {
    // return this.usuarioControl.hasError('required') ? 'Valor requerido' : '';
  }
  getErrorMessagePass() {
    // return this.passControl.hasError('required') ? 'Valor requerido' : '';
  }
  isFieldInvalidU() {
    if (this.username === '') {
      return true;
    } else {
      return false;
    }
  }
  isFieldInvalidP() {
    if (this.password === '') {
      return true;
    } else {
      return false;
    }
  }
}
